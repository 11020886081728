import React from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import { useAuthContext } from "../../../context/auth";

function Start() {
  const { circuitProgram, weightProgram, sendStartTimer, sendSetStep } =
    useAuthContext();
  const program = circuitProgram ?? weightProgram;
  return (
    <LandscapeProgramLayout step={1}>
      <div className="w-full flex-1 flex flex-col justify-center items-center">
        <h1 className="text-[200px] text-white">{program?.name}</h1>
        <button
          className="rounded-[32px] text-[100px] text-black py-4 px-[200px] flex justify-center items-center mt-[60px]"
          style={{
            background:
              "linear-gradient(270deg, #7DE9FF 0%, #7DE9FF 0.01%, #53CAF5 100%)",
          }}
          onClick={() => {
            sendStartTimer();
          }}
        >
          START
        </button>
        <button
          className="text-[40px] text-white mt-[30px]"
          onClick={() => {
            sendSetStep(0);
          }}
        >
          나가기
        </button>
      </div>
    </LandscapeProgramLayout>
  );
}

export default Start;
